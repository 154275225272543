
import { defineComponent, PropType } from "vue";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";

export default defineComponent({
  name: "Tabs",
  emits: ["update:next-tab"],
  data() {
    return {
      tab: this.labels?.length ? this.labels[0] : "",
      timestampToDate
    };
  },
  props: {
    labels: Array as PropType<string[]>
  },
  methods: {
    changeTab(nextTab: string) {
      this.tab = nextTab;
      this.$emit("update:next-tab", this.tab);
    }
  }
});
