
import { mapMutations, mapState } from "vuex";
import LoginForm from "./Forms/LoginForm.vue";
import Modal from "./Modal.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AuthModal",
  components: {
    LoginForm,
    Modal
  },
  props: {
    large: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      authModalShow: (state: any) => state.auth.authModalShow
    })
  },
  methods: {
    ...mapMutations(["toggleAuthModal"])
  }
});
