import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b78103c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-card-content" }
const _hoisted_2 = { class: "description m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "item-card col-12 col-md-3 d-flex justify-content-center",
    style: _normalizeStyle({ backgroundImage: `url(${_ctx.user?.profilePicUrl || 'img/julie.jpg'})` })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.user?.name), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.user?.email), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.user?.phone), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.userCountry), 1)
    ])
  ], 4))
}