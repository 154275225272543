
import ApiController from "@/apiServices/ApiController";
import { UserData } from "@/apiServices/interface";
import { defineComponent } from "@vue/runtime-core";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "ProfileForm",
  props: {
    userId: { type: String, required: true }
  },
  data() {
    return {
      schema: {
        name: "required|min:3|max:100|alpha_spaces",
        email: "required|min:3|max:100|email",
        role: "required",
        password: "min:3|max:100",
        confirm_password: "passwords_mismatch:@password",
        country: "required|country_excluded:Antarctica"
      },
      userData: {
        role: "",
        country: "",
        email: "",
        name: "",
        phone: "",
        profilePicUrl: "",
        password: "",
        confirm_password: ""
      } as UserData,
      countryList: this.$tm("countries"),
      editMode: false
    };
  },
  emits: ["dist-userdata"],
  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    async getUserInfo() {
      const user = await ApiController.user.getUserData(this.userId);
      if (!user) {
        return;
      }
      this.userData = ({ ...this.userData, ...user.data() } as unknown) as UserData;
      this.$emit("dist-userdata", {
        profilePicture: this.userData.profilePicUrl,
        name: this.userData.name
      });
    },
    async updateUserInfo(val: UserData) {
      if (!this.editMode) return;
      const { role, name, country, email, phone } = val;
      await ApiController.user.updateUserData(this.userId, { role, name, country, email, phone });
      ElNotification({
        title: "Success",
        message: "Successfully updated profile.",
        type: "success"
      });
      this.toggleEditMode?.();
    }
  },
  created() {
    this.getUserInfo();
  }
});
