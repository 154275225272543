import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a5ce330"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { value: "Founder" }
const _hoisted_6 = { value: "Member" }
const _hoisted_7 = { value: "Associate" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  type: "submit",
  class: "button mr-auto edit-btn"
}
const _hoisted_13 = { class: "m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vee_field = _resolveComponent("vee-field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_vee_form = _resolveComponent("vee-form")!

  return (_openBlock(), _createBlock(_component_vee_form, {
    class: "col-12 form mx-auto",
    "validation-schema": _ctx.schema,
    onSubmit: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.editMode ? _ctx.toggleEditMode() : _ctx.updateUserInfo($event))),
    "initial-values": _ctx.userData
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_vee_field, {
          type: "text",
          name: "name",
          class: _normalizeClass(["input", _ctx.editMode ? 'readonly' : '']),
          placeholder: "Naam",
          readonly: !_ctx.editMode
        }, null, 8, ["class", "readonly"]),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "name"
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_vee_field, {
          type: "email",
          name: "email",
          class: _normalizeClass(["input", _ctx.editMode ? 'readonly' : '']),
          placeholder: "Email",
          readonly: !_ctx.editMode
        }, null, 8, ["class", "readonly"]),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "email"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_vee_field, {
          type: "phone",
          name: "phone",
          class: _normalizeClass(["input", _ctx.editMode ? 'readonly' : '']),
          placeholder: "Telefoon",
          readonly: !_ctx.editMode
        }, null, 8, ["class", "readonly"]),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "phone"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_vee_field, {
          as: "select",
          name: "role",
          class: _normalizeClass(["input", _ctx.editMode ? 'readonly' : '']),
          readonly: !_ctx.editMode
        }, {
          default: _withCtx(() => [
            _createElementVNode("option", _hoisted_5, _toDisplayString(_ctx.$t("forms.profile.role.founder")), 1),
            _createElementVNode("option", _hoisted_6, _toDisplayString(_ctx.$t("forms.profile.role.member")), 1),
            _createElementVNode("option", _hoisted_7, _toDisplayString(_ctx.$t("forms.profile.role.partner")), 1)
          ]),
          _: 1
        }, 8, ["class", "readonly"]),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "role"
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_vee_field, {
          as: "select",
          name: "country",
          class: _normalizeClass(["input", _ctx.editMode ? 'readonly' : '']),
          readonly: !_ctx.editMode
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryList, (country) => {
              return (_openBlock(), _createElementBlock("option", {
                key: country.code,
                value: _ctx.$rt(country.code)
              }, _toDisplayString(_ctx.$rt(country.name)), 9, _hoisted_9))
            }), 128))
          ]),
          _: 1
        }, 8, ["class", "readonly"]),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "country"
        })
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("forms.profile.change_password")), 1),
        _createVNode(_component_vee_field, {
          type: "password",
          name: "password",
          class: _normalizeClass(["input", _ctx.editMode ? 'readonly' : '']),
          placeholder: "Wachtwoord",
          readonly: !_ctx.editMode
        }, null, 8, ["class", "readonly"]),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "password"
        })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_vee_field, {
          type: "password",
          name: "confirm_password",
          class: _normalizeClass(["input", _ctx.editMode ? 'readonly' : '']),
          placeholder: "Herhaal Wachtwoord",
          readonly: !_ctx.editMode
        }, null, 8, ["class", "readonly"]),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "confirm_password"
        })
      ]),
      _createElementVNode("button", _hoisted_12, [
        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$tc("forms.profile.button", !_ctx.editMode ? 2 : 1)), 1)
      ])
    ]),
    _: 1
  }, 8, ["validation-schema", "initial-values"]))
}