import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar_toggle_button = _resolveComponent("navbar-toggle-button")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass([[!_ctx.backgroundTransparent || _ctx.showMenu ? '' : 'transparent'], "navbar d-flex justify-content-between"])
  }, [
    _renderSlot(_ctx.$slots, "navbar-logo"),
    (_ctx.isPortableDevice)
      ? (_openBlock(), _createBlock(_component_navbar_toggle_button, {
          key: 0,
          "onUpdate:showMenu": _ctx.toggleShowMenu,
          showMenu: _ctx.showMenu
        }, null, 8, ["onUpdate:showMenu", "showMenu"]))
      : _createCommentVNode("", true),
    _createElementVNode("ul", {
      class: _normalizeClass(["navbar-nav", { 'd-flex': !_ctx.isPortableDevice, 'navbar-nav-open': _ctx.showMenu }])
    }, [
      _renderSlot(_ctx.$slots, "navbar-menu")
    ], 2),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}