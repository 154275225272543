
import { defineComponent, PropType } from "vue";
import { UserData } from "@/apiServices/interface";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";

export default defineComponent({
  name: "ProfileCard",
  props: {
    user: { type: Object as PropType<UserData> }
  },
  data() {
    return {
      countryList: this.$tm("countries"),
      timestampToDate
    };
  },
  computed: {
    userCountry() {
      const countryCode = this.user?.country;
      const countrylist = this.countryList as [];
      let countryName;

      countrylist.forEach((country: { code: string; name: string }) => {
        if (this.$rt(country.code) === countryCode) {
          countryName = this.$rt(country.name);
        }
      });
      return countryName ?? countryCode;
    }
  }
});
