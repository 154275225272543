
import { UserData } from "@/apiServices/interface";
import { defineComponent } from "@vue/runtime-core";
import { ElNotification } from "element-plus";
import { mapState } from "vuex";

export type CountryType = {
  name: string;
  code: string;
};

export default defineComponent({
  name: "RegisterForm",
  data() {
    return {
      schema: {
        name: "required|min:3|max:100|alpha_spaces",
        email: "required|min:3|max:100|email",
        password: "required|min:3|max:100",
        confirm_password: "passwords_mismatch:@password",
        country: "required|country_excluded:Antarctica",
        role: "required"
      },
      userData: {
        country: "US",
        memberSince: new Date()
      },
      countryList: this.$tm("countries"),
      reg_in_submission: false
    };
  },
  methods: {
    async register(values: UserData) {
      this.reg_in_submission = true;

      try {
        await this.$store.dispatch("register", values);
      } catch (error) {
        this.reg_in_submission = false;
        ElNotification({
          title: "Failed",
          message: "An unexpected error occured. Please try again later.",
          type: "error"
        });
        return;
      }
      this.reg_in_submission = false;
      ElNotification({
        title: "Success",
        message: "The account has been created.",
        type: "success"
      });
    }
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.auth.userId
    })
  }
});
