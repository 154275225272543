import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_login_form = _resolveComponent("login-form")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    showModal: _ctx.authModalShow,
    close: _ctx.toggleAuthModal
  }, {
    title: _withCtx(() => [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t("authmodal.title")), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_login_form)
    ]),
    _: 1
  }, 8, ["showModal", "close"]))
}