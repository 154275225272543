import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group col-12 col-md-6" }
const _hoisted_2 = { class: "form-group col-12 col-md-6" }
const _hoisted_3 = { class: "form-group col-12 col-md-6" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "form-group col-12 col-md-6" }
const _hoisted_6 = { value: "Founder" }
const _hoisted_7 = { value: "Member" }
const _hoisted_8 = { value: "Associate" }
const _hoisted_9 = { class: "form-group col-12 col-md-6" }
const _hoisted_10 = { class: "form-group col-12 col-md-6" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = {
  key: 0,
  class: "loadingspinner",
  src: "img/spinner.svg",
  alt: ""
}
const _hoisted_13 = {
  key: 1,
  class: "m-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vee_field = _resolveComponent("vee-field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_vee_form = _resolveComponent("vee-form")!

  return (_openBlock(), _createBlock(_component_vee_form, {
    class: "col-8 form d-flex flex-wrap mx-auto",
    "validation-schema": _ctx.schema,
    onSubmit: _ctx.register,
    "initial-values": _ctx.userData
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_vee_field, {
          type: "text",
          name: "name",
          class: "input",
          placeholder: "Naam"
        }),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "name"
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_vee_field, {
          type: "email",
          name: "email",
          class: "input",
          placeholder: "Email"
        }),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "email"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_vee_field, {
          as: "select",
          name: "country",
          class: "input"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryList, (country) => {
              return (_openBlock(), _createElementBlock("option", {
                key: country.code,
                value: _ctx.$rt(country.code)
              }, _toDisplayString(_ctx.$rt(country.name)), 9, _hoisted_4))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "country"
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_vee_field, {
          as: "select",
          name: "role",
          class: "input"
        }, {
          default: _withCtx(() => [
            _createElementVNode("option", _hoisted_6, _toDisplayString(_ctx.$t("forms.register.role.founder")), 1),
            _createElementVNode("option", _hoisted_7, _toDisplayString(_ctx.$t("forms.register.role.member")), 1),
            _createElementVNode("option", _hoisted_8, _toDisplayString(_ctx.$t("forms.register.role.partner")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "country"
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_vee_field, {
          name: "password",
          bails: false
        }, {
          default: _withCtx(({ field, errors }) => [
            _createElementVNode("input", _mergeProps({
              class: "input",
              type: "password",
              placeholder: "Wachtwoord"
            }, field), null, 16),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(errors, (error) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "alert",
                key: error
              }, _toDisplayString(error), 1))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_vee_field, {
          type: "password",
          name: "confirm_password",
          class: "input",
          placeholder: "Herhaal Wachtwoord"
        }),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "confirm_password"
        })
      ]),
      _createElementVNode("button", {
        type: "submit",
        disabled: _ctx.reg_in_submission,
        class: "button mr-auto ml-3"
      }, [
        (_ctx.reg_in_submission)
          ? (_openBlock(), _createElementBlock("img", _hoisted_12))
          : (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.$t("forms.register.button")), 1))
      ], 8, _hoisted_11)
    ]),
    _: 1
  }, 8, ["validation-schema", "onSubmit", "initial-values"]))
}