
import { defineComponent } from "vue";
import NavbarToggleButton from "./NavbarToggleButton.vue";

export default defineComponent({
  name: "Navbar",
  props: {
    colorOnScroll: {
      type: String,
      default: "0"
    },
    isPortableDevice: Boolean
  },
  provide() {
    return { toggleShowMenu: this.toggleShowMenu };
  },
  components: {
    NavbarToggleButton
  },
  data() {
    return {
      showMenu: false,
      backgroundTransparent: true,
      currentScrollValue: 0
    };
  },
  methods: {
    toggleShowMenu(bool: boolean) {
      this.showMenu = bool;
    },
    scrollListener() {
      let scrollValue = document.body.scrollTop || document.documentElement.scrollTop;
      this.backgroundTransparent = scrollValue < Number(this.colorOnScroll);
    }
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.scrollListener);
  }
});
