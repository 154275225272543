
import { ElNotification } from "element-plus";
import { defineComponent } from "vue";
import { mapMutations } from "vuex";

export default defineComponent({
  name: "LoginForm",
  props: {
    large: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loginSchema: {
        email: "required|email",
        password: "required|min:3|max:32"
      },
      login_in_submission: false
    };
  },
  methods: {
    ...mapMutations(["toggleAuthModal"]),
    async login(values: { email: string; password: string }) {
      this.login_in_submission = true;

      try {
        await this.$store.dispatch("login", values);
      } catch (error) {
        this.login_in_submission = false;
        ElNotification({
          title: "Login failed",
          message: "Invalid login details.",
          type: "error"
        });
        return;
      }
      ElNotification({
        title: "Login success",
        message: "You are now logged in.",
        type: "success"
      });
      this.toggleAuthModal();
    }
  }
});
