import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "loadingspinner",
  src: "img/spinner.svg",
  alt: ""
}
const _hoisted_5 = {
  key: 1,
  class: "m-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vee_field = _resolveComponent("vee-field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_vee_form = _resolveComponent("vee-form")!

  return (_openBlock(), _createBlock(_component_vee_form, {
    class: "col-12 col-lg-l8 form mx-auto",
    "validation-schema": _ctx.loginSchema,
    onSubmit: _ctx.login
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_vee_field, {
          class: "input",
          type: "email",
          name: "email",
          placeholder: "Email"
        }),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "email"
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_vee_field, {
          class: "input",
          type: "password",
          name: "password",
          placeholder: "Wachtwoord"
        }),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "password"
        })
      ]),
      _createElementVNode("button", {
        type: "submit",
        disabled: _ctx.login_in_submission,
        class: "button mr-auto"
      }, [
        (_ctx.login_in_submission)
          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
          : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("forms.login.button")), 1))
      ], 8, _hoisted_3)
    ]),
    _: 1
  }, 8, ["validation-schema", "onSubmit"]))
}