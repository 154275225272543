
import ApiController from "@/apiServices/ApiController";
import { FormActions } from "vee-validate";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContactForm",
  data() {
    return {
      schema: {
        name: "required|min:3|max:100|alpha_spaces",
        email: "required|min:3|max:100|email"
      },
      body: "",
      reg_in_submission: false,
      reg_show_alert: false,
      reg_alert_msg: "Bericht is verzonden!"
    };
  },
  methods: {
    async submitContactForm(
      values: { name: string; email: string },
      { resetForm }: FormActions<Record<string, unknown>>
    ) {
      const { name, email } = values;
      this.reg_in_submission = true;

      const submitResponse = await ApiController.data.submitContactForm(name, email, this.body);
      this.reg_in_submission = false;

      if (submitResponse) {
        this.reg_show_alert = true;
        this.body = "";
        resetForm();
      }
    }
  }
});
