
import MainNavbar from "@/components/Layout/MainNavbar.vue";
import MainFooter from "@/components/Layout/MainFooter.vue";

export default {
  name: "App",
  components: {
    MainNavbar,
    MainFooter
  }
};
