
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "Modal",
  props: {
    close: { type: Function as PropType<() => void> },
    showModal: {
      type: Boolean
    },
    large: {
      type: Boolean,
      default: false
    }
  }
});
