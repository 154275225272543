
import { defineComponent } from "vue";
import { Parallax, Carousel, AchievementCounter, ContactForm } from "@/components";
import router from "@/router";

export default defineComponent({
  name: "Home",
  components: { Parallax, Carousel, AchievementCounter, ContactForm },
  methods: {
    redirect(route: string) {
      router.push(route);
    }
  }
});
