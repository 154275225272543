
import { EventItem, NewsItem } from "@/apiServices/interface";
import { defineComponent, PropType } from "vue";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";
import Card from "./Card.vue";
import ApiController from "@/apiServices/ApiController";

export enum FetchType {
  News = "News",
  Events = "Events"
}

export default defineComponent({
  name: "Carousel",
  components: { Card },
  props: {
    type: {
      type: String,
      default: ""
    },
    fetchType: {
      type: String as PropType<FetchType>,
      default: FetchType.Events
    }
  },
  data() {
    return {
      newsList: [] as NewsItem[],
      eventList: [] as EventItem[],
      limit: 4,
      pendingRequest: false,
      timestampToDate
    };
  },
  methods: {
    async getNewsItems() {
      if (this.pendingRequest) {
        return;
      }
      this.pendingRequest = true;
      const newsItems = await ApiController.news.getNewsItemList(this.limit);

      if (newsItems?.docs.length) {
        newsItems.docs.map(doc =>
          this.newsList.push(({ id: doc.id, ...doc.data() } as unknown) as NewsItem)
        );
      }

      this.pendingRequest = false;
    },
    async getEventItems() {
      if (this.pendingRequest) {
        return;
      }
      this.pendingRequest = true;
      const eventItems = await ApiController.events.getEventItemList(this.limit);

      if (eventItems?.docs.length) {
        eventItems.docs.map(doc =>
          this.eventList.push(({ id: doc.id, ...doc.data() } as unknown) as EventItem)
        );
      }
      this.pendingRequest = false;
    }
  },
  computed: {
    carouselType() {
      if (window.innerWidth < 767) {
        return "";
      }
      return this.type;
    },
    listToRender() {
      if (this.fetchType === FetchType.Events) {
        return this.eventList;
      }
      return this.newsList;
    }
  },
  created() {
    if (this.fetchType === FetchType.Events) {
      this.getEventItems();
      return;
    }
    this.getNewsItems();
  }
});
