
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "Dropdown",
  props: {
    direction: {
      type: String,
      default: "down"
    },
    title: String,
    icon: String,
    position: String,
    hideArrow: Boolean,
    tag: {
      type: String,
      default: "li"
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  provide() {
    return {
      closeDropDown: this.closeDropDown
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
    },
    closeDropDown() {
      this.isOpen = false;
    }
  }
});
