import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-132f259c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-6 col-md-3" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = {
  key: 2,
  class: "special_icon mb-3"
}
const _hoisted_4 = { class: "col-6 col-md-3" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = {
  key: 2,
  class: "special_icon mb-3"
}
const _hoisted_7 = { class: "col-6 col-md-3" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = {
  key: 2,
  class: "special_icon mb-3"
}
const _hoisted_10 = { class: "col-6 col-md-3" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 2,
  class: "special_icon mb-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent('div'), {
    class: "row container text-center mx-auto",
    ref: "achievementcounter"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.editable)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: "fas fa-edit col-1",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.edit('milesCount')))
              }))
            : _createCommentVNode("", true),
          (_ctx.inEditMode === 'milesCount')
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 1,
                type: "number",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.milesCount) = $event)),
                ref: "milesCount",
                onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateAchievementData('miles', _ctx.milesCount))),
                class: "input"
              }, null, 544)), [
                [_vModelText, _ctx.milesCount]
              ])
            : (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.milesCount), 1))
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("achievementcounter.miles")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.editable)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: "fas fa-edit col-1",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.edit('clientsCount')))
              }))
            : _createCommentVNode("", true),
          (_ctx.inEditMode === 'clientsCount')
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 1,
                type: "number",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.clientsCount) = $event)),
                ref: "clientsCount",
                onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateAchievementData('clients_served', _ctx.clientsCount))),
                class: "input"
              }, null, 544)), [
                [_vModelText, _ctx.clientsCount]
              ])
            : (_openBlock(), _createElementBlock("h2", _hoisted_6, _toDisplayString(_ctx.clientsCount), 1))
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("achievementcounter.happy_faces")), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          (_ctx.editable)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: "fas fa-edit col-1",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.edit('eventsCount')))
              }))
            : _createCommentVNode("", true),
          (_ctx.inEditMode === 'eventsCount')
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 1,
                type: "number",
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.eventsCount) = $event)),
                ref: "eventsCount",
                onBlur: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateAchievementData('events_hosted', _ctx.eventsCount))),
                class: "input mx-2"
              }, null, 544)), [
                [_vModelText, _ctx.eventsCount]
              ])
            : (_openBlock(), _createElementBlock("h2", _hoisted_9, _toDisplayString(_ctx.eventsCount), 1))
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("achievementcounter.events")), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          (_ctx.editable)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: "fas fa-edit col-1",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.edit('donationsCount')))
              }))
            : _createCommentVNode("", true),
          (_ctx.inEditMode === 'donationsCount')
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 1,
                type: "number",
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.donationsCount) = $event)),
                ref: "donationsCount",
                onBlur: _cache[11] || (_cache[11] = ($event: any) => (_ctx.updateAchievementData('donation_amount', _ctx.donationsCount))),
                class: "input"
              }, null, 544)), [
                [_vModelText, _ctx.donationsCount]
              ])
            : (_openBlock(), _createElementBlock("h2", _hoisted_12, _toDisplayString(_ctx.$n(_ctx.donationsCount, "currency")), 1))
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("achievementcounter.raised_for_charity")), 1)
      ])
    ]),
    _: 1
  }, 512)), [
    [_directive_click_outside, _ctx.closeEditMode]
  ])
}