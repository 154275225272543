import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "description my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card = _resolveComponent("card")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!

  return (_ctx.listToRender.length)
    ? (_openBlock(), _createBlock(_component_el_carousel, {
        key: 0,
        type: _ctx.carouselType
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listToRender, (item) => {
            return (_openBlock(), _createBlock(_component_el_carousel_item, {
              key: item.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_card, {
                  linkToItem: `/${_ctx.fetchType.toLowerCase()}/${item.id}`
                }, {
                  img: _withCtx(() => [
                    _createElementVNode("img", {
                      class: "d-block",
                      src: item?.basePictureUrl ?? 'img/julie.jpg',
                      alt: item?.id
                    }, null, 8, _hoisted_1)
                  ]),
                  title: _withCtx(() => [
                    _createElementVNode("h1", null, _toDisplayString(item.title), 1)
                  ]),
                  timestamp: _withCtx(() => [
                    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.timestampToDate(item.createdAt)), 1)
                  ]),
                  description: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(item.description), 1)
                  ]),
                  _: 2
                }, 1032, ["linkToItem"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["type"]))
    : _createCommentVNode("", true)
}