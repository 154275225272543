import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "col-12 row tab" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "title my-0 py-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (label, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["col-4 tab-label", [_ctx.tab === label ? 'tab-label-active' : '']]),
        key: index,
        onClick: ($event: any) => (_ctx.changeTab(label))
      }, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(label), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}