
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavbarToggleButton",
  props: {
    showMenu: Boolean
  },
  emits: ["update:show-menu"],
  provide() {
    return {
      closeNavbar: this.toggleMenuBar
    };
  },

  methods: {
    toggleMenuBar() {
      this.$emit("update:show-menu", !this.showMenu);
    }
  }
});
