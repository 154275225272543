import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7af51e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vee_field = _resolveComponent("vee-field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_vee_form = _resolveComponent("vee-form")!

  return (_openBlock(), _createBlock(_component_vee_form, {
    "validation-schema": _ctx.schema,
    onSubmit: _ctx.submitContactForm,
    class: "form"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_vee_field, {
          class: "input",
          type: "text",
          name: "name",
          placeholder: "Naam"
        }),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "name"
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_vee_field, {
          class: "input",
          type: "textarea",
          name: "email",
          placeholder: "Email"
        }),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "email"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("textarea", {
          placeholder: "Bericht",
          rows: "5",
          class: "input",
          name: "body",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.body) = $event))
        }, null, 512), [
          [_vModelText, _ctx.body]
        ]),
        _createVNode(_component_ErrorMessage, {
          class: "alert",
          name: "body"
        })
      ]),
      (_ctx.reg_show_alert)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.reg_alert_msg), 1))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        type: "submit",
        disabled: _ctx.reg_in_submission,
        class: "button"
      }, _toDisplayString(_ctx.$t("forms.contact.button")), 9, _hoisted_5)
    ]),
    _: 1
  }, 8, ["validation-schema", "onSubmit"]))
}