
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainFooter",
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
});
