import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "no-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(["dropdown", [{ show: _ctx.isOpen }, { dropdown: _ctx.direction === 'down' }, { dropup: _ctx.direction === 'up' }]]),
    "aria-haspopup": "true",
    "aria-expanded": _ctx.isOpen,
    onClick: _ctx.toggleDropDown
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "title", {}, () => [
        _createElementVNode("a", {
          class: _normalizeClass(["dropdown-toggle nav-link", { 'no-caret': _ctx.hideArrow }]),
          "data-toggle": "dropdown"
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(_ctx.icon)
          }, null, 2),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1)
        ], 2)
      ]),
      _createElementVNode("ul", {
        class: _normalizeClass(["dropdown-menu", [{ 'dropdown-menu-right': _ctx.position === 'left' }, { show: _ctx.isOpen }]])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ]),
    _: 3
  }, 8, ["class", "aria-expanded", "onClick"])), [
    [_directive_click_outside, _ctx.closeDropDown]
  ])
}