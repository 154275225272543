import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["menu-item", { 'is-active': _ctx.isActive ? _ctx.isActive() : null }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
    title: _ctx.title
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(`fas fa-${_ctx.icon}`)
    }, null, 2)
  ], 10, _hoisted_1))
}