export default {
  "nav_bar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuws"])},
    "events": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evenementen"])},
      "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankomende evenementen"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evenementenhistorie"])}
    },
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Ons"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over ons"])},
      "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])}
    },
    "members": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])}
    }
  },
  "forms": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuws"])},
    "contact": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzend"])}
    },
    "login": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
    },
    "profile": {
      "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord aanpassen"])},
      "role": {
        "founder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprichter"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lid"])},
        "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])}
      },
      "button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Opslaan"]), _normalize(["Bewerken"])])}
    },
    "register": {
      "role": {
        "founder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprichter"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lid"])},
        "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer"])}
    }
  },
  "layout": {
    "nav": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardian Angels 4 kids"])}
    },
    "footer": {
      "interested_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geinteresseerd in wat wij doen?"])},
      "interested_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem dan contact met ons op, dan kunnen we samen kijken naar de mogelijkheden om een onvergetelijke dag te organiseren."])},
      "contact_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "contact_adres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junopad 4, Spijkenisse"])},
      "contact_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+31612345678"])},
      "contact_kvk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KVK: 1234567"])},
      "more_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lees meer"])},
      "more_about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over ons"])},
      "more_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuws & verhalen"])},
      "more_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankomende evenementen"])},
      "links_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handige links"])},
      "links_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placeholder"])}
    }
  },
  "achievementcounter": {
    "miles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Km gereden"])},
    "happy_faces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blije gezichten"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evenementen"])},
    "raised_for_charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgehaald voor goede doelen"])}
  },
  "authmodal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Ons"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij zijn een nederlandse trikestichting die toerritten aanbied voor kansarme kinderen/volwassene of anderzijds met beperkingen, ons team is volledig uitgerust met de kennis en ervaring om voor deze groep leuke, stoere en vooral veilige dagtrips te verzorgen. Dit doen wij met heel veel plezier en natuurlijk onze trikes!"])},
    "founders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprichters"])},
    "location": {
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie Zuid-Holland"])},
      "office_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons kantoor is gelegen in Spijkenisse nabij Rotterdam."])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junopad 4, Spijkenisse"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+31612345678"])},
      "kvk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KVK: 1234567"])}
    },
    "contact_form": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar om te rijden?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem dan contact met ons op via onderstaand formulier."])}
    }
  },
  "partners": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])}
  },
  "events": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evenementen"])},
    "add_or_update": {
      "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Update item"]), _normalize(["Nieuw item"])])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzend"])}
    },
    "events": {
      "prev_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige maand"])},
      "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandaag"])},
      "next_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende maand"])},
      "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgelicht"])}
    },
    "detail": {
      "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerelateerd"])}
    },
    "history": {
      "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgelicht"])}
    }
  },
  "manage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer"])},
    "tabs": {
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
      "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuws"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])}
    },
    "overview": {
      "events": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evenementen"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal: "])},
        "items": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Geen items"]), _normalize(["1 evenement"]), _normalize([_interpolate(_named("count")), " evenementen"])])},
        "title_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
        "created_on_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt op"])},
        "description_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omschrijving"])},
        "action_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw evenement  "])}
      },
      "news": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuws"])},
        "amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aantal: ", _interpolate(_named("items"))])},
        "items": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Geen nieuwsitems"]), _normalize(["1 nieuwsitem"]), _normalize([_interpolate(_named("count")), " nieuwsitems"])])},
        "title_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
        "created_on_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt op"])},
        "description_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omschrijving"])},
        "action_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw nieuwsitem  "])}
      },
      "users": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal: "])},
        "users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Geen gebruikers"]), _normalize(["1 gebruiker"]), _normalize([_interpolate(_named("count")), " gebruikers"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe gebruiker  "])}
      }
    }
  },
  "news": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuws"])},
    "add_or_update": {
      "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Update item"]), _normalize(["Nieuw item"])])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzend"])}
    },
    "news": {
      "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgelicht"])}
    },
    "detail": {
      "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerelateerd"])}
    }
  },
  "users": {
    "profile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload nieuwe foto"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jpg/png bestanden minder dan 500kb"])}
    },
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer"])},
      "new_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe gebruiker"])}
    }
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom bij de "])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Guardian Angels"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar voor de rit van je leven?"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij zijn een nederlandse trikestichting die toerritten aanbied voor kansarme kinderen/volwassene of anderzijds met beperkingen, ons team is volledig uitgerust met de kennis en ervaring om voor deze groep leuke, stoere en vooral veilige dagtrips te verzorgen. Dit doen wij met heel veel plezier en natuurlijk onze trikes!"])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lees meer"])},
    "activity": {
      "one": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beleven"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" De wind in je haar en de kracht van de motor die je voelt ronken vanuit je stoel."])}
      },
      "two": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toeren"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The great open road awaits! Het stalen ros staat klaar om je een rit te bezorgen die je niet snel vergeet."])}
      },
      "three": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nooit meer vergeten!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een dag triken is een dag leven alsof je je op Route 66 begeeft, zeker een ervaring die je nog lang bij zal blijven."])}
      }
    },
    "recent_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente evenementen"])},
    "location": {
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie Zuid-Holland"])},
      "office_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons kantoor is gelegen in Spijkenisse nabij Rotterdam."])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junopad 4, Spijkenisse"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+31612345678"])},
      "kvk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KVK: 1234567"])}
    },
    "contact_form": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar om te rijden?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem dan contact met ons op via onderstaand formulier."])}
    }
  },
  "countries": [
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AF"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AX"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albanië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AL"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algerije"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DZ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerikaanse Maagdeneilanden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VI"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerikaans-Samoa"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AO"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antarctica"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua en Barbuda"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentinië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AW"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AU"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbeidzjan"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AZ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahama's"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BS"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrein"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BH"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BD"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BB"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["België"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BZ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BJ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BT"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BO"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonaire, Sint Eustatius en Saba"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BQ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnië en Herzegovina"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BA"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BW"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouvet"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BV"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazilië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britse Maagdeneilanden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brits Indische Oceaanterritorium"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IO"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BN"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarije"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BF"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BI"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodja"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KH"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CA"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centraal-Afrikaanse Republiek"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CF"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chili"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CL"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CN"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Christmaseiland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CX"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocoseilanden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoren"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo-Brazzaville"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo-Kinshasa"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CD"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookeilanden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CK"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CU"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaçao"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CW"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CY"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denemarken"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DK"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DJ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominicaanse Republiek"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DO"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duitsland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EC"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypte"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SV"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equatoriaal-Guinea"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GQ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ER"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethiopië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ET"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faeröer"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FO"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falklandeilanden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FK"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FJ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipijnen"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PH"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FI"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frankrijk"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franse Zuidelijke en Antarctische Gebiede"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TF"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans-Guyana"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GF"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans-Polynesië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PF"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GA"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GH"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GI"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GD"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Griekenland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groenland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GL"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GP"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GU"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GT"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinee"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GN"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinee-Bissau"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GW"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GY"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haïti"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HT"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heard en McDonaldeilanden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HN"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongarije"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongkong"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ierland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IJsland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IS"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IN"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irak"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israël"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IL"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ivoorkust"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CI"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JP"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jemen"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordanië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JO"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaaimaneilanden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KY"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaapverdië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kameroen"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazachstan"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KZ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenia"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirgizië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleine afgelegen eilanden van de Verenigde Staten"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koeweit"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KW"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XK"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroatië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LA"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LS"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LV"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libanon"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LB"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LY"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LI"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litouwen"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LT"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxemburg"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LU"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macau"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MO"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedonië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MK"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagaskar"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MW"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldiven"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MV"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maleisië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ML"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MT"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marokko"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MA"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshalleilanden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MH"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinique"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MQ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritanië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MU"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YT"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MX"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronesia"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldavië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MD"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MC"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MN"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ME"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MZ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NA"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NL"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NP"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NI"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw-Caledonië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NC"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw-Zeeland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NZ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NU"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noordelijke Marianen"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MP"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noord-Korea"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KP"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noorwegen"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norfolk"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NF"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeganda"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oekraïne"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UA"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oezbekistan"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UZ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oostenrijk"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AT"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oost-Timor"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TL"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PK"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PW"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palestina"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PS"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua-Nieuw-Guinea"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PY"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitcairneilanden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PN"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polen"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto Rico"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roemenië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RO"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RU"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RW"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Barthélemy"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BL"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Kitts en Nevis"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KN"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Lucia"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LC"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Pierre en Miquelon"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent en de Grenadines"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VC"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salomonseilanden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SB"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WS"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saoedi-Arabië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tomé en Principe"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ST"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SN"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RS"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychellen"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SC"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SL"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint-Helena, Ascension en Tristan da Cunha"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SH"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint-Maarten"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MF"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint Maarten"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SX"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SI"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slowakije"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soedan"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SD"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SO"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanje"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spitsbergen en Jan Mayen"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SJ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LK"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SZ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syrië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SY"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tadzjikistan"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TJ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TW"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TZ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TH"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TG"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TK"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad en Tobago"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TT"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tsjaad"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TD"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tsjechië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CZ"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunesië"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TN"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkije"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks- en Caicoseilanden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TC"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TV"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UY"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VU"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaticaanstad"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verenigde Arabische Emiraten"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verenigde Staten"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verenigd Koninkrijk"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GB"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VN"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis en Futuna"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WF"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Westelijke Sahara"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EH"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wit-Rusland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BY"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZM"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZW"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuid-Afrika"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZA"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuid-Georgia en de Zuidelijke Sandwicheilanden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GS"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuid-Korea"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KR"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuid-Soedan"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SS"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweden"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SE"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwitserland"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CH"])}
    }
  ]
}